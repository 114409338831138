.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: white;
  font-weight: 400; 
  color: #333;
}

h1, h2 {
  font-weight: 800;
  color: #1d1d1f;
  font-size: xx-large;
}

a {
  color: #007aff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.container {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
}

.text-center img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li::before {
  content: '•';
  color: #007aff;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

